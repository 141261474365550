import { useCallback, useEffect, useState } from 'react';
import enableInlineVideo from 'iphone-inline-video';
import logo from './logo.svg';
// import video from './oui.mp4';
import { Player } from 'video-react';

import './App.css';

function App() {
  const [showSelection, setShowSelection] = useState(false)
  const [rotation, setRotation] = useState(false);

  const orientation = (a) => {
    // console.log('here lala', document.body);
    // console.log(document.getElementById('videoBackground'))
    // const node = document.getElementById('videoBackground');
    // node.style.display = 'none';
    // node.style.zIndex = '-1';

    // console.log('here lala', node);

    // document.body.attributes.setNamedItem('toto')

    // document.body.style.backgroundColor = 'red';

  };


  // useEffect(() => {
  //   if (window.DeviceOrientationEvent) {
  //     window.addEventListener('deviceorientation', orientation, false);
  //     // document.getElementById("doeSupported").innerText = "Supported!";
  //   }
  // })


  useEffect(() => {
    enableInlineVideo(document.querySelector('video'));
  }, []);

  useEffect(() => {
    if (showSelection) {
        document.body.classList.add('modal-open');
    } 
  }, [showSelection]);
  return (
    <div className="App">
         {/* <video width="100%" id="videoBackground" autoplay loop muted playsInline>
      <source src="../public/test.mp4" type="video/mp4" />invert(1) brightness(0.5)
    </video> */}
     { !showSelection && <div>
    <img id="cover" src="./ELLE_FINALE.jpg" alt="img1" onClick={() => window.open('https://linktr.ee/heiovas')} />
    <span style={{
      position: 'absolute',
      top: 20,
      right: 20,
      color: 'white',
      zIndex: 100,
      backgroundColor: 'black',
      padding: 5,
    }}>heiovasound@gmail.com | M.C</span>

    {/* <div className="grid " >
      <a aria-label="link-1" target="_blank" rel="noopener" draggable="false" className="link l-d2 w-inline-block">
          <img className="content__img" src="./cover.jpg" alt="img1"/>

          <img className="content__img" src="./cover.jpg" alt="img1"/>

      </a>
      <div>

      </div>
  </div> */}
  </div>}
  { showSelection &&
      <div className="selection">
        <div>
          
          </div>
          <div>
            </div>
    </div>
  }
    </div>
  );
}

export default App;
